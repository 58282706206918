<template>
  <div>
    <v-container v-if="!showResult">
      <v-row>
        <v-col cols="12" class="d-flex">
          <span class="d-flex justify-center align-center">
            <img
              src="@/assets/icons/selfie-search.svg"
              alt="age-icon"
              width="22"
              height="22"
            />
          </span>
          <div class="ml-2">
            <span class="main-heading">Face Search</span>
            <v-icon
              v-if="!infoText"
              @click="infoText = true"
              class="info-icon cursor-pointer ml-2"
              >mdi-information-outline</v-icon
            >
          </div>
        </v-col>
        <v-col cols="12" class="" v-if="infoText">
          <v-alert color="age-alert" class="alert-card">
            <template v-slot:append>
              <img
                src="@/assets/icons/close-icon.svg"
                @click="infoText = false"
                class="cursor-pointer"
                height="22"
                width="22"
                alt="close-icon"
              />
            </template>
            <span class="info-text-card">
              Explore the power of Face Search, where you can effortlessly match
              a selfie or any face image against a collection of multiple
              images. Simply provide the images you wish to match, and our
              system will return the results of the matched images. Haven't
              added any images for matching yet? Use our
              <router-link
                class="info-text-card-link"
                id="nav-item-live-perform-ocr"
                target="_blank"
                to="face-enroll"
              >
                'Enroll Face'
              </router-link>
              feature to create your own database of images.
            </span>
          </v-alert>
        </v-col>
      </v-row>
      <!-- Before enrolled Screen  -->
      <v-row v-if="!showCard && recordImages.length == 0">
        <v-col cols="12" class="no-record">
          <div v-if="isLoading">
            <v-progress-circular
              :width="3"
              color="#173dd0"
              indeterminate
            ></v-progress-circular>
            <span class="pl-2"> Loading...</span>
          </div>
          <div v-else class="result-btns">
            <img
              src="@/assets/icons/no-face-found.svg"
              alt="no-face-found"
              width="30"
              height="30"
            />
            <p class="no-record-text mb-0">No Face Enrolled!</p>
            <p class="no-record-text mb-2">Please enroll the face.</p>
            <v-btn depressed class="try-more" @click="showCard = true">
              <img
                src="@/assets/icons/plus.svg"
                height="12"
                width="12"
                alt="plus"
                class="mr-1"
              />
              New Face Enroll
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- After enrolled Screen -->
      <v-row v-else>
        <!-- first time enroll view -->
        <v-col v-if="recordImages.length == 0" offset="3" cols="6">
          <v-card
            class="age-verification-card"
            :class="{ 'card-progress': isProcessing }"
          >
            <v-card-title class="card-title"> Selfie/Image </v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-row v-if="!imageSrc" class="card-body">
                <v-col cols="6" class="option">
                  <div class="option-child" @click="takeSelfie()">
                    <img
                      src="@/assets/icons/camera-icon.svg"
                      height="20"
                      width="20"
                      alt="camera-icon"
                    />
                    <span class="option-text">Take Selfie</span>
                  </div>
                  <v-divider class="vertical-divider" vertical></v-divider>
                  <div class="option-child" @click="browseClick()">
                    <img
                      src="@/assets/icons/file-upload.svg"
                      height="20"
                      width="20"
                      alt="file-upload-icon"
                    />
                    <div class="option-text">Upload Selfie</div>
                  </div>
                </v-col>
                <v-col cols="6" class="file-limit-text"
                  >Maximum size 3 MB</v-col
                >
              </v-row>
              <v-row v-if="imageSrc">
                <v-col cols="12" class="pa-0">
                  <img class="preview-img" id="image-preview" :src="imageSrc" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="card-footer">
              <div v-if="isProcessing">
                <v-progress-circular
                  :width="3"
                  color="#173dd0"
                  indeterminate
                ></v-progress-circular>
                <span class="pl-2"> Processing Data...</span>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- Overall view -->
        <v-col
          v-if="recordImages.length > 0"
          :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6"
          class="result-btns"
        >
          <div class="mb-1">
            <span class="main-heading">Enrolled Faces ({{ totalRecord }})</span>
          </div>
          <v-row>
            <v-col cols="6" v-for="img in recordImages" :key="img._id">
              <div class="searched-img-results">
                <img
                  class="searched-img"
                  id="image-preview"
                  :src="img.thumbnail_url"
                />
              </div>
            </v-col>
          </v-row>
          <v-btn depressed class="view-result mt-3" @click="goToEnroll()">
            <span class="px-1 no-record-text">View all enrolled faces</span>
            <img
              src="@/assets/icons/arrow-next.svg"
              height="15"
              width="15"
              alt="next-arrow"
            />
          </v-btn>
        </v-col>
        <v-col
          v-if="recordImages.length > 0"
          :cols="$vuetify.breakpoint.mdAndDown ? 12 : 6"
          class="enroll-face"
        >
          <div :class="$vuetify.breakpoint.mdAndDown ? 'mb-5' : 'mb-2'">
            <span class="main-heading">Face Search</span>
          </div>
          <div class="extra-margin">
            <v-card
              class="age-verification-card"
              :class="{ 'card-progress': isProcessing }"
            >
              <div v-if="showRetry" class="step-retry">
                <p class="card-retry cursor-pointer" @click="retry">
                  Retry
                  <img
                    src="@/assets/icons/retry-blue.svg"
                    height="12"
                    width="12"
                    alt="retry"
                    class="ml-1"
                  />
                </p>
              </div>
              <v-card-title class="card-title"> Selfie/Image </v-card-title>
              <v-card-text class="d-flex justify-center">
                <v-row v-if="!imageSrc" class="card-body">
                  <v-col cols="6" class="option">
                    <div class="option-child" @click="takeSelfie()">
                      <img
                        src="@/assets/icons/camera-icon.svg"
                        height="20"
                        width="20"
                        alt="camera-icon"
                      />
                      <span class="option-text">Take Selfie</span>
                    </div>
                    <v-divider class="vertical-divider" vertical></v-divider>
                    <div class="option-child" @click="browseClick()">
                      <img
                        src="@/assets/icons/file-upload.svg"
                        height="20"
                        width="20"
                        alt="file-upload-icon"
                      />
                      <div class="option-text">Upload Selfie</div>
                    </div>
                  </v-col>
                  <v-col cols="6" class="file-limit-text"
                    >Maximum size 3 MB</v-col
                  >
                </v-row>
                <v-row v-if="imageSrc">
                  <v-col cols="12" class="pa-0">
                    <img
                      class="preview-img"
                      id="image-preview"
                      :src="imageSrc"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="card-footer">
                <div v-if="isProcessing">
                  <v-progress-circular
                    :width="3"
                    color="#173dd0"
                    indeterminate
                  ></v-progress-circular>
                  <span class="pl-2"> Processing Data...</span>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- Result Screen -->
    <v-container v-else>
      <v-row>
        <v-col cols="6">
          <div>
            <span class="main-heading">Searched Image</span>
          </div>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12" class="d-flex justify-end result-btns">
              <v-btn @click="retry" depressed class="try-more">
                <span class="px-1">Try 1:N Again</span>
                <img
                  src="@/assets/icons/retry-icon.svg"
                  height="12"
                  width="12"
                  alt="next-arrow"
                />
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="searched-img-container">
            <img class="searched-img" id="image-preview" :src="imageSrc" />
          </div>
        </v-col>
      </v-row>
      <p class="main-heading mt-5">
        Similar Images ({{ searchResult.length }} images matched)
      </p>
      <v-row v-if="searchResult.length > 0">
        <v-col
          :class="{ 'display-center': $vuetify.breakpoint.mdAndDown }"
          md="3"
          sm="4"
          cols="12"
          v-for="item in searchResult"
          :key="item.id"
        >
          <div
            class="searched-img-results"
            :class="{ 'text-center': $vuetify.breakpoint.mdAndDown }"
          >
            <img class="searched-img" id="image-preview" :src="item[2]" />
            <p class="mb-0 ml-3">
              Similarity:
              <span class="substr-text"
                >{{ getSimilarityScore(item[1]) }}%</span
              >
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="no-record">
          <div class="text-center">
            <img
              src="@/assets/icons/no-face-found.svg"
              alt="no-face-found"
              width="30"
              height="30"
            />
            <p class="no-record-text mb-0">No Face Found!</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- Camera Component -->
    <Camera
      v-if="startCamera"
      @attached-image="getImage"
      @close-camera-dialog="closeCamera"
    />
    <input
      ref="file-uploader"
      id="upload-file"
      class="d-none"
      type="file"
      accept="image/jpeg,image/JPEG,image/jpg,image/JPG,image/PNG,image/png"
      @change="handleImageUpload($event)"
    />
  </div>
</template>

<script>
import Camera from "../common/Camera.vue";
import ApiService from "../../services/Api";

import {
  showErrorMessage,
  showSimpleErrorMessage,
} from "../../utils/showError";
import { API_URL } from "@/utils/env.js";
export default {
  name: "OneToMany",
  components: { Camera },
  data() {
    return {
      infoText: true,
      imageSrc: null,
      showCard: false,
      startCamera: false,
      isProcessing: false,
      showResult: false,
      searchFaceResultInterval: false,
      setTimeoutInterval: null,
      searchResult: [],
      baseUrl: API_URL,
      selfieImage: "",
      clientSecret: "",
      isPhotoTaken: false,
      isLoading: false,
      recordImages: [],
      showRetry: false,
      totalRecord: 0,
      fileSizeLimit: 3 * 1024 * 1024,
    };
  },

  methods: {
    /**
     * Initiates the process of taking a selfie by enabling the camera.
     */
    takeSelfie() {
      this.startCamera = true;
    },

    /**
     * Handles the image data received from taking a selfie, sets the image source, and sends the image for comparison.
     * @param {Object} data - The image data object containing base64Image and file properties.
     */
    getImage(data) {
      this.imageSrc = data.base64Image;
      this.selfieCameraBlob = data.file;
      this.sendForComparing(data.file);
    },

    /**
     * Closes the camera after taking a selfie.
     */
    closeCamera() {
      this.startCamera = false;
    },
    /**
     * @Description
     * 1. this method is used to intilize the browse click
     * @param none
     * @return none
     **/
    browseClick() {
      this.$refs?.["file-uploader"]?.click();
    },

    /**
     * Converts the similarity score value to a percentage format.
     * @param {number} val - The similarity score value.
     * @returns {number} - The similarity score as a percentage.
     */
    getSimilarityScore(val) {
      return parseInt(val * 100, 10);
    },

    /**
     * Retrieves all media records for display.
     */
    getAllMedia() {
      this.isLoading = true;
      ApiService.GetRequest("/merchant/client")
        .then((res) => {
          this.clientSecret = res.data.result.data.client_secret;
          let data = {
            pagination: 4,
            page: 1,
            is_pagination: true,
            transaction_type: "liveness_photo_id_match",
          };
          ApiService.PostRequest(
            "enrolled-faces",
            data,
            "iframe",
            res.data.result.data.client_secret
          )
            .then((response) => {
              this.isLoading = false;
              this.recordImages = response.data.result.data.data;
              this.totalRecord = response.data.result.data.total;
            })
            .catch((error) => {
              this.isLoading = false;
              showErrorMessage(error);
            });
        })
        .catch((error) => {
          this.isLoading = false;
          showErrorMessage(error);
        });
    },

    /**
     * Redirects to the face enrollment page.
     */
    goToEnroll() {
      this.$router.push("face-enroll");
    },

    /**
     * Reloads the current page.
     */
    goBack() {
      location.reload();
    },

    /**
     * Sets the flag indicating whether a photo is captured or not.
     * @param {boolean} value - Flag indicating if a photo is captured.
     */
    isCaptured(value) {
      this.isPhotoTaken = value;
    },

    /**
     * Starts the selfie camera.
     * Sets the flag to indicate that a photo has been taken and disables starting the document camera.
     */
    startSelfie() {
      this.startSelfieCamera = true;
      this.isPhotoTaken = true;
      this.disabledStartDocumentCamera = true;
    },

    /**
     * Handles the capture of a selfie.
     * Enables starting the document camera, sets the selfie blob, and initiates the process of comparing the selfie.
     * @param {Blob} blob - The blob representing the captured selfie image.
     */
    selfieCaptured(blob) {
      this.disabledStartDocumentCamera = false;
      this.selfieCameraBlob = blob;
      this.sendForComparing();
    },

    /**
     * Handles the upload of an image file.
     * If the file size is within the limit, it processes the image.
     * @param {Event} event - The event object containing information about the uploaded file.
     */
    handleImageUpload(event) {
      if (
        event?.target?.files[0] &&
        event?.target?.files[0]?.size < this.fileSizeLimit
      ) {
        const file = event.target.files[0];
        this.imageSrc = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          const blob = new Blob([reader.result], { type: file.type });
          this.selfieCaptured(blob);
        };
      } else {
        showSimpleErrorMessage("image size cannot be greater than 3 mb.");
      }
    },

    /**
     * Initiates the process of comparing the captured selfie with enrolled faces.
     * Sends a request to create a transaction for face search.
     */
    sendForComparing() {
      this.isProcessing = true;
      const formData = new FormData();
      formData.append("type", "face_search");
      formData.append("file", this.selfieCameraBlob, "file.jpeg");
      ApiService.PostRequest(
        "face-search",
        formData,
        "iframe",
        this.clientSecret
      )
        .then((res) => {
          this.searchFaceResultInterval = setInterval(() => {
            this.fetchFacedResults(res.data.result.data.reference_id);
          }, 2000);
          this.stopInterval();
        })
        .catch((error) => {
          showErrorMessage(error);
          this.showRetry = true;
          this.isProcessing = false;
          if (error.response.status === 400) {
            setTimeout(this.retry.bind(this), 2500);
          }
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },

    /**
     * Fetches the result of the face search transaction using the reference ID.
     * If matches are found, displays the search results.
     * @param {string} referenceId - The reference ID of the face search transaction.
     */
    fetchFacedResults(referenceId) {
      const formData = new FormData();
      formData.append("reference_id", referenceId);
      ApiService.PostRequest("result", formData, "iframe", this.clientSecret)
        .then((res) => {
          if (
            res.data.result.data?.face_search_response?.result?.matched_faces
              .length > 0
          ) {
            clearInterval(this.searchFaceResultInterval);
            clearTimeout(this.setTimeoutInterval);
            this.isProcessing = false;
            this.showResult = true;
            this.searchResult =
              res.data.result.data.face_search_response.result.matched_faces;
          } else if (
            res.data.result.data?.face_search_response?.result.matched_faces ===
              0 ||
            res.data.result.data?.face_search_response?.result.matched_faces
              .length == 0
          ) {
            clearInterval(this.searchFaceResultInterval);
            clearTimeout(this.setTimeoutInterval);
            this.isProcessing = false;
            this.showResult = true;
          }
        })
        .catch((error) => {
          showErrorMessage(error);
          clearInterval(this.searchFaceResultInterval);
          clearTimeout(this.setTimeoutInterval);
          this.isProcessing = false;
          setTimeout(this.retry.bind(this), 2500);
        });
    },
    /**
     * @Description
     * 1. Set a timeout to stop the interval after 1 minutes (60000 milliseconds)
     * @param none
     * @return none
     **/
    stopInterval() {
      this.setTimeoutInterval = setTimeout(() => {
        clearInterval(this.searchFaceResultInterval);
        this.isProcessing = false;
        showSimpleErrorMessage("Unable to Extract the Data.");
        setTimeout(this.retry.bind(this), 2500);
      }, 60000);
    },

    /**
     * Reloads the current page.
     */
    retry() {
      window.location.reload();
    },
  },
  beforeDestroy() {
    clearInterval(this.searchFaceResultInterval);
    clearTimeout(this.setTimeoutInterval);
  },
  async mounted() {
    this.getAllMedia();
  },
};
</script>

<style scoped>
@media (max-width: 403px) {
  .sec-font {
    font-size: 18px !important;
  }
}

@media (max-width: 398px) {
  /* .sec-text {
    padding-left: 30%;
  }

  .v-btn{
    max-width: 100%;
  } */

  .btn-txt {
    font-size: 9px !important;
  }
}

@media (max-width: 344px) {
  /* .sec-text {
    padding-left: 30%;
  }

  .v-btn{
    max-width: 100%;
  } */

  .btn-txt {
    font-size: 7px !important;
  }
}
.container {
  margin-top: 2rem;
}
.no-record {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-record-text {
  color: #292b30;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.age-verification-card {
  width: 100%;
  height: 300px;
  padding: 0 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.card-progress {
  height: 310px;
}
.img-container {
  height: 300px;
  /* width: 500px; */
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.captured-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.img-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.info-text-card {
  width: 50rem;
  color: #000;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.info-text-card-link {
  color: #213cc8;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration: none;
}

.main-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
  font-size: 20px;
}

.age-main-container {
  padding: 40px;
}
.age-alert {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fafafa;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.age-alert /deep/ .v-alert__wrapper {
  align-items: start;
}

.age-alert /deep/ .v-alert__content {
  text-align: justify;
  padding-right: 40px;
}

.selfie-search-card {
  width: 20.9375rem;
  height: 19.375rem;
  padding: 0 16px;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.selfie-search-card-result {
  width: 65%;
  height: 19.375rem;
  padding: 0 16px;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.card-title {
  display: flex;
  justify-content: center;
  color: #292b30;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.card-body {
  width: 80%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px dashed #e3e3e3;
  background: #f7f7f7;
  column-gap: 10px;
}
.enroll-face {
  border-left: 1px solid #e3e3e3af;
}
.preview-img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  aspect-ratio: 16/9;
  border: 1px dashed #e3e3e3;
  border-radius: 6px;
}
.searched-img-container {
  width: 30%;
  padding: 10px;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.searched-img-results {
  padding: 10px;
  border-radius: 0.625rem;
  background: #fff;
  box-shadow: 0px 1.4px 13px 0px rgba(0, 0, 0, 0.03),
    0px 7px 80px 0px rgba(0, 0, 0, 0.05) !important;
}
.searched-img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  aspect-ratio: 16/9;
  border-radius: 0.625rem;
}
.card-body-img {
  width: 19rem;
  height: 13rem;
}
.card-footer {
  display: flex;
  justify-content: center;
  color: #909295;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.step-retry {
  width: 100%;
  display: flex;
  justify-content: end;
}
.card-step {
  color: #a6a2a2;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  padding-top: 5px;
  margin-bottom: 0;
}
.card-retry {
  color: #213cc8;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  padding-top: 5px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.file-limit-text {
  color: #909295;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 0 0;
  text-align: center;
}

.horizontal-divider {
  width: 54px;
  height: 20px;
  min-height: 20px;
  color: #909295;
}
.horizontal-divider-main {
  display: flex;
  align-items: center;
}
.vertical-divider {
  width: 5px;
  height: 20px;
  min-height: 20px;
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  align-self: end;
}
.vertical-divider-long {
  width: 5px;
  height: 100%;
  min-height: 20px;
  color: #909295;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  align-self: end;
}
.option {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 10px;
  padding: 0 0 5px;
}
.option-child {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 10px;
  cursor: pointer;
}
.option-text {
  color: #213cc8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.substr-text {
  color: #213cc8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0 2px;
}
.extra-margin {
  height: 80%;
  display: flex;
  align-items: center;
}
.result-btns {
  column-gap: 16px;
}

.result-btns .view-result {
  display: flex;
  column-gap: 4px;
  color: #000;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.result-btns .try-more {
  padding: 10px 15px;
  border-radius: 6px;
  background: #213cc8 !important;
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.secnod-row {
  padding-top: 10px;
}

.alert-card {
  border-radius: 0.375rem;
  border: 1px solid #e3e3e3;
  background: #fafafa;
}

.top-row {
  margin: 2rem 0 0 1.5rem;
}

.faceMatchIcon {
  width: 1.375rem;
  height: 1.375rem;
}

.info-icon {
  font-size: 16px;
  margin-top: -4px; /* Adjust the margin-top to align the icon properly */
}
.disable-view {
  opacity: 0.5;
  cursor: not-allowed;
}
.flex-cards {
  display: flex;
  justify-content: space-evenly;
}
.display-center {
  display: flex;
  justify-content: center;
}
</style>
<style lang="scss" scoped>
@use "../../assets/scss/breakpoints.scss" as b;
@include b.narrower-than(b.$bp-medium) {
  .searched-img-container {
    width: 100%;
  }
  .searched-img-results {
    width: 80%;
  }
}
</style>
